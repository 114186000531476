import axios from "axios";
import { Notification, MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
// import errorCode from "@/utils/errorCode";


let downloadLoadingInstance;

// const errorCodeData = errorCode[i18n.locale];

axios.defaults.headers["Conntent-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: '/api',
  // 一小时超时
  timeout: 1000 * 60 * 60,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 语言标识
    // config.headers.language = localStorage.getItem("lang") || i18n.locale;
    //console.log(config)
    return config
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // // 未设置状态码则默认成功状态
    // const code = res.data.code || 200;
    // // 获取错误信息
    // const msg = errorCodeData[code] || res.data.msg || errorCodeData["default"];
    // // 二进制数据则直接返回
    // if (
    //   res.request.responseType === "blob" ||
    //   res.request.responseType === "arraybuffer"
    // ) {
    //   return res.data;
    // }
    // if (code === 401) {
    //   MessageBox.confirm(
    //     errorCodeData['loginTip'],
    //     errorCodeData['tipTitle'],
    //     {
    //       confirmButtonText: errorCodeData['rTip'],
    //       cancelButtonText: errorCodeData['close'],
    //       type: "warning",
    //     }
    //   )
    //     .then(() => {
    //       store.dispatch("LogOut").then(() => {
    //         location.href = "/";
    //       });
    //     })
    //     .catch(() => {});
    //   return Promise.reject(errorCodeData['outLoginTip']);
    // } else if (code === 500) {
    //   console.log(msg);
    //   return Promise.reject(new Error(msg || errorCodeData["500"]));
    // } else if (code !== 200 && code !== 300) {
    //   Notification.error({
    //     title: msg,
    //   });
    //   return Promise.reject("error");
    // } else {
    //   return res.data;
    // }

    return res.data;
  },
  (error) => {
    // const { message, response } = error;
    // const httpStatus = response?.status;
    // if (httpStatus === 500) {
    //   Message({
    //     message: errorCodeData["500"],
    //     type: "error",
    //     duration: 5 * 1000,
    //   });
    //   return Promise.reject(error);
    // }
    // if (httpStatus === 401) {
    //   setTimeout(()=>{
    //     localStorage.clear();
    //     location.reload();
    //   },2000)
    // } else if (message == "Network Error") {
    //   message = errorCodeData["network"];
    // } else if (message.includes("timeout")) {
    //   message = errorCodeData["timeout"];
    // } else if (message.includes("Request failed with status code")) {
    //   message = `${errorCodeData["tip"]}${message.substr(message.length - 3)}${
    //     errorCodeData["err"]
    //   }`;
    // }
    // Message({
    //   message: message || response.statusText,
    //   type: "error",
    //   duration: 5 * 1000,
    // });
    return Promise.reject(error);
  }
);

export default service;
