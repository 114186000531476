<template>
    <div style="margin-top: 10px">
        <el-row>
            <el-col :span="8">
                <dv-decoration-8 style="width:100%;height:50px;"/>
            </el-col>
            <el-col :span="8">
                <dv-decoration-11
                        style="width:96%;height:60px;color: #fff;margin: 0 auto;font-size: 40px;font-weight: 600">大松工序看板
                </dv-decoration-11>
                <dv-decoration-5 style="width:100%;height:30px;margin: -5px auto 0"/>
            </el-col>
            <el-col :span="8">
                <dv-decoration-8 :reverse="true" style="width:100%;height:50px;"/>
            </el-col>
        </el-row>


        <dv-border-box-1 :style="{width:screenWidth>=1533?'96%':'100%','overflow': 'hidden','margin': '10px auto'}">
            <dv-scroll-board :config="config" style="width:1400px;height:900px;margin: 20px auto;"/>
        </dv-border-box-1>
    </div>
</template>

<script>
    import {getSelectBpordertList} from '@/api/index'

    export default {
        name: 'HomeView',
        components: {},
        data() {
            return {
                screenWidth: document.documentElement.clientWidth,
                config: {
                    header: ['产品编码', '客户简称', '需求日期', '前工序', '当前工序', '后工序'],
                    data: [
                        ['50056985', '111', '2023-01-11', 'DYTDC 引铜刀槽', '焊吊板', '氮化'],
                        ['50056985', '111', '2023-01-11', 'DYTDC 引铜刀槽', '焊吊板', '氮化'],
                        ['50056985', '111', '2023-01-11', 'DYTDC 引铜刀槽', '焊吊板', '氮化'],
                        ['50056985', '111', '2023-01-11', 'DYTDC 引铜刀槽', '焊吊板', '氮化'],
                        ['50056985', '111', '2023-01-11', 'DYTDC 引铜刀槽', '焊吊板', '氮化'],
                        ['50056985', '111', '2023-01-11', 'DYTDC 引铜刀槽', '焊吊板', '氮化'],
                        ['50056985', '111', '2023-01-11', 'DYTDC 引铜刀槽', '焊吊板', '氮化'],
                    ],
                    index: true,
                    columnWidth: [50],
                    align: ['center'],
                    headerBGC: '#2F3196',
                    indexHeader: '',
                    oddRowBGC: '#003C78',
                    evenRowBGC: '#006794',
                    rowNum: 6
                }

            }
        },
        created() {
            //this.getList();
        },
        methods: {
            /*getList() {
                getSelectBpordertList().then(res => {
                    res.data.forEach((item, index) => {
                        let arr = [];
                        arr[0] = item.pinvcode;
                        arr[1] = item.custabbname;
                        arr[2] = item.pdate;
                        arr[3] = item.opProcessQD;
                        arr[4] = item.opProcessDQ;
                        arr[5] = item.item.opProcessXD.pmcode + ' ' + item.opProcessXD.pmname;
                        this.data.push(arr);
                    })
                })
                console.log(this.data)
            }*/
        },
        mounted() {
            window.onresize = () => {
                return (() => {
                    this.screenWidth = document.body.clientWidth
                })
            }
        },

    }
</script>

<style scoped>
    ::v-deep .dv-scroll-board .header {
        /*border-top: 2px solid #695CB4;
        border-bottom: 2px solid #695CB4;
        color: #695CB4;*/
        font-weight: 600;
        font-size: 35px;
        height: 80px;

    }

    ::v-deep .dv-scroll-board .header div {
        margin-top: 22.5px;
        text-align: center;

    }

    ::v-deep .dv-scroll-board .rows div {
        /*background-color: white;*/
        border-bottom: 1px dashed #fff;
        /*color: black;*/
        font-size: 22px;
        text-align: center;
    }

    ::v-deep .dv-scroll-board .rows div:nth-child(5) {
        background-color: #3CC555;
    }

    ::v-deep .dv-scroll-board .rows div:nth-child(6) {
        background-color: #ff4e44;
    }

    ::v-deep .dv-scroll-board .rows div:nth-child(7) {
        background-color: #8B9196;
    }

    /* ::v-deep .dv-scroll-board .rows {
         border-bottom: 1px dashed #2F3196;

     }*/
    ::v-deep .dv-scroll-board .rows .row-item {
        height: 100px;
    }
</style>
